import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";

import { withAuthorization } from '../Session';

import noTransactionsImg from "../../assets/undraw_refreshing_beverage_td3r 1.svg"
import defaultUserIcon from "../../assets/person_24px.svg"


class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.goToOtherPersonsPage.bind(this);
  }

  goToOtherPersonsPage(payment) {
    if (payment.remitterUid === this.props.authUser.uid) {
      if (payment.pageId !== this.props.pageId) {
        this.props.history.push(payment.pageId);
        // Have to refresh because these match the same Route so don't reload automatically
        window.location.reload();
      }
    } else if (payment.remitterPageId !== null) {
      if (payment.remitterPageId !== this.props.pageId) {
        this.props.history.push(payment.remitterPageId);
        // Have to refresh because these match the same Route so don't reload automatically
        window.location.reload();
      }      
    }
  }

  render() {
    if (this.props.paymentHistory === null) {
      return <div className="spinner"><Spinner animation="border" /></div>;
    }

    if (this.props.paymentHistory.length === 0) {
      return (
        <div className="profile-page-tab-placeholder">
          <div>
            <img src={noTransactionsImg} alt=""></img>
            <br/>
            <br/>
            <div>No transaction history...yet!</div>
          </div>
        </div>
      );
    }

    return (
      <div className="payment-history-list">
        {this.props.paymentHistory
          .sort((a,b) => b.createdAt.localeCompare(a.createdAt)) // Reverse sort by date
          .map(item =>
        <button onClick={() => this.goToOtherPersonsPage(item)} key={item.paymentId} className="payment-history-item">
          <img className="payment-history-photo" alt=""
            src={item.beneficiaryUid !== this.props.authUser.uid ?
              (item.beneficiaryImgUrl ? item.beneficiaryImgUrl : defaultUserIcon) :
              (item.remitterImgUrl ? item.remitterImgUrl : defaultUserIcon)}>
            </img>
          <div className="payment-history-users-and-reference">
            <div className="payment-history-users">
              {item.remitterUid === this.props.authUser.uid ?
                "You paid " + item.beneficiaryDisplayName :
                item.remitterName !== null ?
                  item.remitterName + " paid you" :
                  "Someone paid you"
              }
            </div>
            <div className="payment-history-reference">
              {item.message ? item.message : ""}
            </div>
          </div>
          <div className="payment-history-amount-and-date">
            <div className="payment-history-amount">
              {item.remitterUid === this.props.authUser.uid ?
                <div className="payment-history-amount-negative">
                  {"-£" + Number.parseFloat(item.amountInPence/100).toFixed(2)}
                </div>
                :
                <div className="payment-history-amount-positive">{
                  "+£" + Number.parseFloat(item.amountInPence/100).toFixed(2)}
                </div>
              }
            </div>
            <div className="payment-history-date">
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        </button>)
      }
    </div>);
  }
}

// Only signed-in users see history
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(PaymentHistory));
