import React from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import withAuthentication from "./withAuthentication";

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.LANDING);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }
    
    render() {
      if (condition(this.props.authUser)) {
        return (
          <Component {...this.props} />
        );
      }
      return null;
    }
  }
  
  return withRouter(withAuthentication(WithAuthorization));
};

export default withAuthorization;