import React, { Component } from 'react';

import StepHeader from '../SignUp/StepHeader';

import fromIcon from "../../assets/smiley-icon.svg"

class EnterName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "from") {
      this.setState({from: value})
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.from) {
      return false;
    }
    return this.props.handleSubmit(event);
  }

  render() {
    var fromFieldAttributes = {
      name: "from",
      type: "text",
      minLength: "1",
      maxLength: "20",
      placeholder: "Who's it from?",
    };
    return (
      <div className="enter-amount-page-container">
        <StepHeader title="" handleBack={this.props.goBack}/>
        <div className="enter-name-body">
          <form className="enter-amount-main-container" onSubmit={this.handleSubmit} onChange={this.handleChange} autoComplete="off">
          <div>{/* spacer */}</div>
          <div className="enter-name-header">{"Let " + this.props.requesterName + " know who's paying"}</div>
          <div className="enter-name-inputs">
            <label>
              <div className={"enter-amount-name-active"}>
                <img className={"enter-amount-name-img-active"} src={fromIcon} alt=""></img>
                <input className={"enter-amount-name-input"}
                autoFocus={true}
                {...fromFieldAttributes}
                style={{width: (this.state.from ? (this.state.from.length + 2).toString() : fromFieldAttributes.placeholder.length.toString()) + "ch"}}/>
              </div>
            </label>
          </div>
          <input
            className={this.state.from ? "primary-button"  : "disabled-button"}
            type="submit"
            value={"Pay £" + Number.parseFloat(this.props.amountInPence / 100).toFixed(2)} />
        </form>
        </div>
      </div>
    )
  }
}

export default EnterName;