import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthentication } from "../Session";

import StepHeader from '../SignUp/StepHeader'; // TODO: move this
import BurgerMenu from '../BurgerMenu';

class ConfirmBank extends Component {

  render() {
  return (
    <div className="page-container">
      <StepHeader title="" handleBack={this.props.goBack}/>
      <BurgerMenu/>
      <div className="confirm-page-body">
        <div className="requester-photo-container">
          <img className="profile-img" src={this.props.ownerInfo.imageUrl} alt={this.props.ownerInfo.displayName}></img>
        </div>
        <div>
          You're about to pay:
          <br/><b>{this.props.ownerInfo.displayName}</b>
        </div>
        <div className="request-amount">
          {"£" + (Number.parseInt(this.props.amountInPence) / 100).toFixed(2)}
        </div>
        <div className="request-message">
          {this.props.message}
        </div>
      </div>
      {//has the promise returned?
      this.props.isLoading ?
        <div className="confirming-bank-message">
          {"Contacting " + this.props.provider.displayable_name + "..."}
        </div>
      :
        <div className="confirming-bank-message">
          {"Ready! Click to open " + this.props.provider.displayable_name}
        </div>
      }
        <div className="bank-logo">
          <img className="confirm-page-bank-provider-icon" src={this.props.provider.icon} alt={this.props.provider.displayable_name}></img>
        </div>
      {//has the promise returned?
      this.props.isLoading ?
        <button className="primary-button" onClick={() => {}}>
            <div className="spinner-in-button"><Spinner animation="border" /></div>
        </button>
      :
        <a className="primary-button" href={this.props.paymentLink}>
          {"Pay £" + (Number.parseInt(this.props.amountInPence) / 100).toFixed(2)}
        </a>
      }
    </div>);
  }
}

export default withAuthentication(withFirebase(withRouter(ConfirmBank)));