import React, { Component } from 'react';
import Spinner from "react-bootstrap/Spinner";

import { withAuthentication } from "../Session";
import SendAction from './SendAction';

import cancelIcon from "../../assets/cancel-icon.svg"
import messageIcon from "../../assets/message-icon.svg"
import fromIcon from "../../assets/smiley-icon.svg"
import logo from "../../assets/logo.svg"
import checkMarkIcon from "../../assets/check-mark-icon.svg"

const steps = {
  ENTER_AMOUNT: 0,
  ENTER_MESSAGE: 1,
  ENTER_NAME: 2,
}

const states = ['inactive', 'inactive', 'active', 'previous', 'more-previous']

class EnterAmount extends Component {
  constructor(props) {
    super(props);

    this.finalStep = this.props.authUser ? steps.ENTER_MESSAGE : steps.ENTER_NAME;

    this.state = {
      step: steps.ENTER_AMOUNT,
      amountInPounds: "0.00",
      message: "",
      from: '',
      canProgress: false,
      submitted: false,
    }
    this.amountInput = React.createRef();
    this.messageInput = React.createRef();
    this.fromInput = React.createRef();
    this.inputRefs = [this.amountInput, this.messageInput, this.fromInput];
  }

  isValidAmount(amountInPoundsString) {
    var decimalParts = (amountInPoundsString + "00").split(".");
    var amountInPenceInt;
    if (decimalParts.length === 1) {
      // There's no decimal point, we've already add two zeros to multiply by 100, so we're done.
      amountInPenceInt = Number.parseInt(decimalParts[0]);
    } else if (decimalParts.length === 2) {
      if (decimalParts[1].length > 4) {
        return false;
      }
      var amountInPenceString = decimalParts[0] + decimalParts[1].slice(0,2) + "." + decimalParts[1].slice(2);
      amountInPenceInt = Number.parseInt(amountInPenceString);
    } else {
      return false;
    }
    if (amountInPenceInt <= 0 || amountInPenceInt > 50000) {
      return false;
    }
    return true;
  }

  isValidMessage(message) {
    return (message && message.length <= 25)
  }

  isValidName(name) {
    return (name && name.length <= 20)
  }

  isValidInput(step) {
    switch (step) {
      case steps.ENTER_AMOUNT:
        if (!this.state.amountInPounds) {
          return false;
        }
        return this.isValidAmount(this.state.amountInPounds.toString());
      case steps.ENTER_MESSAGE:
        if (!this.state.message) {
          return false;
        }
        return this.isValidMessage(this.state.message);
      case steps.ENTER_NAME:
        if (!this.state.from) {
          return false;
        }
        return this.isValidName(this.state.from);
      default:
        throw Error("Unexpected step: ", step)
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "amountInPounds") {
      var amountValid = false;
      if (value && this.isValidAmount(value.toString())) {
        amountValid = true;
      }
      this.setState({
        amountInPounds: value,
        canProgress: amountValid,
      })
    }
    if (name === "message") {
      this.setState({message: value, canProgress: this.isValidMessage(value)})
    }
    if (name === "from") {
      this.setState({from: value, canProgress: this.isValidName(value)})
    }
  }

  onFocusOnInput(event, step) {
    this.setState({step: step, canProgress: this.isValidInput(step)});
  }

  onNext(event) {
    event.preventDefault();
    if (this.state.canProgress && !this.submitted) {
      if (this.state.step < this.finalStep) {
        this.setState(
          {step: this.state.step + 1, canProgress: this.isValidInput(this.state.step + 1)},
          () => this.inputRefs[this.state.step].current.focus());        
        return false;
      } else {
        this.setState({submitted: true})
        if (this.props.sendAction === SendAction.PAYMENT) {
          this.props.handleSubmit(event)
        } else {
          this.props.handleSubmit(event).catch((error) => {
            console.error('Error creating request', error);
            this.setState({submitted: false})
          });
        }
      }
    }
  }

  render() {
    var amountFieldAttributes = {
      name: "amountInPounds",
      type: "number",
      step: "0.01",
      min: "0.01",
      max: "500",
      placeholder: '0.00',
    };
    var messageFieldAttributes = {
      name: "message",
      type: "text",
      minLength: "1",
      maxLength: "25",
      placeholder: "What's it for?",
    };
    var fromFieldAttributes = {
      name: "from",
      type: "text",
      minLength: "1",
      maxLength: "20",
      placeholder: "Who's it from?",
    };

    return (
      <div className="enter-amount-page-container">
        <button className="enter-amount-cancel-button" onClick={this.props.goBack}>
          <img src={cancelIcon} alt=""></img>
        </button>
        <form className="enter-amount-main-container" onSubmit={(e) => this.onNext(e)} onChange={this.handleChange} autoComplete="off">
          <div>{/* Spacer */}</div>
          <div className="enter-amount-inputs">
            <label className={"enter-amount-amount-label-" + states[this.state.step + 2]}>
              <div className={"enter-amount-amount-label-header-" + states[this.state.step + 2]}>
                {this.props.sendAction === SendAction.PAYMENT ? "Paying " + this.props.ownerInfo.displayName : "Requesting"}
              </div>
              <div className={"enter-amount-amount-" + states[this.state.step + 2]}>
                <div className={"enter-amount-amount-img-" + states[this.state.step + 2]}>
                {this.props.sendAction === SendAction.PAYMENT ? 
                    <img className="profile-img" src={this.props.ownerInfo.imageUrl} alt=""></img>
                  :
                    <img className="enter-amount-request-img" src={logo} alt=""></img>
                }
                </div>
                £
                <input className="enter-amount-amount-input" ref={this.amountInput}
                  {...amountFieldAttributes}
                  onFocus={(e) => this.onFocusOnInput(e, steps.ENTER_AMOUNT)}
                  autoFocus={true}
                  required
                  style={{width: (this.state.amountInPounds.toString().length + 1).toString() + "ch"}}/>
              </div>
              {this.props.sendAction === SendAction.PAYMENT ?
              <div className={"enter-amount-amount-label-footer-" + states[this.state.step + 2]}>
                 <img className="enter-amount-check-mark-img" src={checkMarkIcon} alt=""></img>
                 <img className="enter-amount-bank-provider-logo" src={this.props.ownerInfo.provider.logo_uri} alt={this.props.ownerInfo.provider.display_name}></img>
                 {"Verified account: " + this.props.ownerInfo.nameOnAccount}
              </div> : ""}
            </label>
            <label className={"enter-amount-message-label-" + states[this.state.step + 1]}>
              <div className={"enter-amount-message-label-header-" + states[this.state.step + 1]}>
                For:
              </div>
              <div className={"enter-amount-message-" + states[this.state.step + 1]}>
                <img className={"enter-amount-message-img-" + states[this.state.step + 1]} src={messageIcon} alt=""></img>
                <input className="enter-amount-message-input" ref={this.messageInput}
                {...messageFieldAttributes}
                onFocus={(e) => this.onFocusOnInput(e, steps.ENTER_MESSAGE)}
                onBlur={(e) => e.target.placeholder = messageFieldAttributes.placeholder}
                style={{width: (this.state.message ? (this.state.message.length + 2).toString() : messageFieldAttributes.placeholder.length.toString()) + "ch"}}/>
              </div>
            </label>
            {!this.props.authUser ?
            <label className={"enter-amount-name-label-" + states[this.state.step]}>
              <div className={"enter-amount-name-" + states[this.state.step]}>
                <img className={"enter-amount-name-img-" + states[this.state.step]} src={fromIcon} alt=""></img>
                <input className={"enter-amount-name-input"} ref={this.fromInput}
                {...fromFieldAttributes}
                onFocus={(e) => this.onFocusOnInput(e, steps.ENTER_NAME)}
                style={{width: (this.state.from ? (this.state.from.length + 2).toString() : fromFieldAttributes.placeholder.length.toString()) + "ch"}}/>
              </div>
            </label>
            : ""}
          </div>
          <button
            className={this.state.canProgress ? (this.props.sendAction === SendAction.PAYMENT ? "enter-amount-primary-button" : "enter-amount-secondary-button")
              : "enter-amount-disabled-button"}>
            {this.state.submitted ? <div className="spinner-in-button"><Spinner animation="border" /></div> :
            this.state.step === this.finalStep ?
              (this.props.sendAction === SendAction.PAYMENT ? "Pay £" + Number.parseFloat(this.state.amountInPounds) : "Create request link")
              : "Next"}
          </button>
        </form>
      </div>
    )
  }
}

export default withAuthentication(EnterAmount);