import React, { Component } from 'react';
import Spinner from "react-bootstrap/Spinner";

import StepHeader from '../SignUp/StepHeader'; // TODO: move this
import providers from '../../assets/payment-providers.json';
import searchIcon from '../../assets/search_24px.svg'

class SelectBank extends Component {
  constructor() {
    super();
    this.allProviders = providers.results;
    this.state = {
      providers: this.allProviders,
      loading: false,
    }
    this.searchProviders = this.searchProviders.bind(this);
  }

  searchProviders(event) {
    var filteredProviders = this.allProviders.filter(provider =>
      provider.displayable_name.toLowerCase().includes(event.target.value.toLowerCase())
    )

    this.setState({providers: filteredProviders});
  }

  onClick(provider) {
    this.setState({loading: true});
    this.props.onClick(provider);
  }

  render() {
    if (this.state.loading) {
      return(
        <div className="spinner-page"><div className="spinner"><Spinner animation="border"/></div></div>
      );
    }

    return(
      <div className="bank-selection-page">
        <StepHeader title="Select your bank" handleBack={this.props.goBack}/>
        <div className="bank-search">
          <img className="bank-search-icon" src={searchIcon} alt=""></img>
          <input className="bank-search-input" type="text" onChange={this.searchProviders} placeholder="Search"></input>
        </div>
        <div className="bank-selection">
          {this.state.providers.map(provider => (
            <button key={provider.id} className="bank-selection-card" onClick={() => this.onClick(provider)}>
              <img className="bank-logo-img" src={provider.icon} alt={provider.displayable_name}></img>
              <div className="bank-name">
                {provider.displayable_name}
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default SelectBank;