import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import FadeIn from "react-fade-in";

import StepHeader from './StepHeader';
import padlockIcon from "../../assets/light-padlock-icon.svg";
import connectBankImg from "../../assets/connect-bank.svg";

class ConnectBank extends Component {
  render() {
    return(
      <div className="page-container">
        <StepHeader title="Connect your bank" handleBack={this.props.handleBack}/>
        <div className="page-body">
          <div>
            To finalize your page, we just need to verify your name and account number with your bank 
          </div>
          <div>
            <img className="vault-img" src={connectBankImg} alt=""></img>
          </div>
        </div>
        <div className="choose-url-button-container">
          <FadeIn key={"fadeIn1"}>
            <button className="primary-button" onClick={this.props.handleConfirm}>
              <img src={padlockIcon} style={{margin: "0 5px 0 0"}} alt=""></img>
               Connect bank
              </button>
          </FadeIn>
        </div>
      </div>
    );
  }
}

// Require users be signed in to see this page
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(ConnectBank));