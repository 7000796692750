import React from "react";
import { Link } from 'react-router-dom';
import Carousel from "react-bootstrap/Carousel";

import * as ROUTES from '../../constants/routes';

import { withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";

import BurgerMenu from '../BurgerMenu';

import logoImg from "../../assets/logo.svg"
import logoTextImg from "../../assets/logo-text.svg"
import transferImg from "../../assets/undraw_transfer_money_rywa 1.svg"
import privacyImg from "../../assets/undraw_privacy_protection_nlwy 1.svg"
import micDropImg from "../../assets/mic-drop.svg"



class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: null,
    };
  }

  getUserPageId() {
    if (this.props.authUser !== null)  {
      this.props.firebase.db.collection("paymentPages").where("user", "==", this.props.authUser.uid).get()
      .then(result => {
        if (result.docs.length > 0) {
          this.setState({pageId: result.docs[0].id});
        }
      })
      .catch(error => {
        console.log('Error getting page for user', this.props.authUser.uid, error);
      });
    } else {
      this.setState({pageId: null});
    }
  }

  componentDidMount() {
    this.getUserPageId();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.authUser !== this.props.authUser) {
      this.getUserPageId();
    }
  }

  render() {
    return (
      <div className="page-container">
        <BurgerMenu/>
        <div className="page-body">
          <div className="landing-carousel">
            <Carousel controls={false}>
              <Carousel.Item>
                <div className="landing-carousel-item">
                  <div className="big-logo-with-logotext">
                    <div className="big-logo">
                      <img src={logoImg} alt=""></img>
                    </div>
                    <div className="big-logotext">
                      <img src={logoTextImg} alt=""></img>
                    </div>
                  </div>
                  <div>
                    Pay anyone, now
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="landing-carousel-item">
                  <div className="carousel-large-img">
                    <img src={transferImg} alt=""></img>
                  </div>
                  <div>
                    No app needed.<br/><br/>
                    Get a personal, unique URL that anyone can send money to.
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="landing-carousel-item">
                  <div className="carousel-large-img">
                    <img src={privacyImg} alt=""></img>
                  </div>
                  <div>
                    Fast, simple, secure.
                    <br/><br/>
                    Payments go directly into your bank account.
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="landing-carousel-item">
                  <br/><br/>
                  <div className="carousel-large-img">
                    <img src={micDropImg} alt=""></img>
                  </div>
                  <div>
                    <br/>
                    Completely free.
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        {this.state.pageId !== null ? 
          <Link className="primary-button" to={this.state.pageId}>See your page</Link> :
          <Link className="primary-button" to={ROUTES.SIGN_UP}>Create account</Link>
        }
      </div>   
    );
  }
}

export default withAuthentication(withFirebase(Landing));