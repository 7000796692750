import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logoImg from "../../assets/logo.svg";
import BurgerMenu from '../BurgerMenu';

class Help extends Component {
  render() {
    return (
      <div className="page-container help-page">
        <BurgerMenu/>
        <button className="help-home-button" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
          <img className="help-home-button-logo" src={logoImg} alt="home"></img>
        </button>
        <div className="help-header">
          Privacy & Security
        </div>
        <div className="help-body">
          <p>
            With Nimble, you have total control over your money - every time you’d like to make a payment, we’ll take you to your bank to approve it. 
            <br/><br/>
            Payments are processed by your bank just like a bank transfer - and just like a bank transfer they are sent directly to your friend’s account. Same if a friend pays you: the money goes directly into your account. Nimble never touches your money. 
            <br/><br/>
            We verify every user’s bank account and show you the name on the account so you can be sure you’re paying the right person.
            <br/><br/>
            Technology to allow this to happen is provided by TrueLayer. TrueLayer uses bank-grade encryption to secure your connection to the bank through Open Banking, and doesn’t store any of your data. They’re an FCA-regulated Account Information Service Provider and Payment Initiation Service Provider (license number 793171). View Truelayer’s <a href="https://truelayer.com/enduser_tos">Terms of Service</a> and <a href="https://truelayer.com/privacy/">Privacy Policy</a>. 
            <br/><br/>
            We never sell your data to 3rd parties. If you’d like to delete your account, delete your data, or have further questions, please reach out to us at <a href="mailto: support@nimble.cash">support@nimble.cash</a>.
          </p>
        </div>
      </div>
      
    );
  }
}

export default withRouter(Help);