import React, { Component } from 'react';
import imageCompression from 'browser-image-compression';
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import uploadPhotoIcon from "../../assets/upload-photo.svg";
import blobImg from "../../assets/profile-pic-chooser-blob.svg";
import logoImg from "../../assets/logo.svg"

// Max size before compression, only loaded locally
const MAX_FILE_SIZE_IN_BYTES = 5 * 1024 * 1024;

const imageCompressionOptions = {
  maxSizeMB: 0.5, // Max size sent to server. If changing this, first change firebase storage rules.
  maxWidthOrHeight: 300,
}

class AccountCreated extends Component {
  constructor(props) {
    super(props);
    // TODO: this state should live on parent so it persists across going fwd and back
    this.state = {
      imgSrc: null, // TODO: default profile image?
      imgFile: null,
      saving: false,
    }
    this.handleFileSelection = this.handleFileSelection.bind(this);
    this.saveImageAndContinue = this.saveImageAndContinue.bind(this);
    this.uploadImageToFirebase = this.uploadImageToFirebase.bind(this);
  }

  componentDidMount() {
    this.setState({imgSrc: this.props.authUser.photoURL})
  }

  handleFileSelection(event) {
    let file = event.target.files[0]
    if (file) {
      this.readImage(file);
    }
  }

  readImage(file) {
    // Check if the file is an image.
    if (file.type && file.type.indexOf('image') === -1) {
      console.log('File is not an image.', file.type, file);
      return;
    }

    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      console.log('File is too big.', file.size, file);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({
        imgSrc: event.target.result,
        imgFile: file,
      });
    }
    reader.readAsDataURL(file);
  }

  saveImageAndContinue() {
    if (this.state.saving) {
      return false;
    }
    if (this.state.imgFile) {
      this.setState({saving: true})
      // Compress and upload to firebase
      imageCompression(this.state.imgFile, imageCompressionOptions)
        .then(this.uploadImageToFirebase)
        .then(this.props.handleContinue)
        //.catch(function (error) {
        //  console.log(error.message);
        //}); TODO SSTOLL
    } else {
      // TODO: save image from user account
      this.props.handleContinue()
    }
  }

  // TODO: move all this profile pic stuff somewhere it can be shared with the profile page
  uploadImageToFirebase(compressedFile) {
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    return this.props.firebase.storage.ref()
      .child('profile-images/' + this.props.authUser.uid + "/profileImg")
      .put(compressedFile)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(downloadURL => {
        return this.props.firebase.updateProfileImage({
          pageId: this.props.pageId,
          imageUrl: downloadURL,
        });
      });
  }

  render() {
    return(
      <div className="page-container">
        <div className="page-header">
          <img className="header-logo" src={logoImg} alt="home"></img>
        </div>
        <div className="account-created-page-body">
          <div>
            Welcome to Nimble,<br/>{this.props.authUser.displayName}!
          </div>
          <input id="profile-pic-input" className="profile-pic-input" type="file" accept="image/*" onChange={this.handleFileSelection}></input>
          <label htmlFor="profile-pic-input">
            <img className="profile-pic-blob-img" src={blobImg} alt=""></img>
            <div className="profile-img-preview-container">
              <img className="profile-img" src={this.state.imgSrc} alt=""></img>
            </div>
            <div className="profile-pic-input-edit">
              edit
              <img src={uploadPhotoIcon} alt=""></img>
            </div> 
          </label>
          <div>{/* spacer */}</div>
          <div>
            Make sure to use a profile photo that your friends can recognize
          </div>
          <div>{/* spacer */}</div>
        </div>
        <button className="primary-button" onClick={this.saveImageAndContinue}>
          {this.state.saving ? <div className="spinner-in-button"><Spinner animation="border" /></div> :
          "Finish"}
        </button>
      </div>
    );
  }
}

// Require users be signed in to see this page
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(AccountCreated));
