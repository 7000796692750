import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logoImg from "../../assets/logo.svg"
import backArrowImg from "../../assets/arrow_back_24px.svg"

class StepHeader extends Component {
  render() {
    return(
      <div className="page-header">
        <div className="header-back-button-container">
          <button className="header-button" onClick={this.props.handleBack}>
            <img src={backArrowImg} alt="back"></img>
          </button>
        </div>
        <div>
          {this.props.title}
        </div>
        {this.props.homeButton ?
          <button className="header-button" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
            <img className="header-logo" src={logoImg} alt="home"></img>
          </button>
        : <div className="header-spacer">{/* Empty span for alignment */}</div>}
      </div>
    );
  }
}

export default withRouter(StepHeader);