import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/firestore';

import * as firebaseui from 'firebaseui';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.storage = firebase.storage();

    if (window.location.hostname === "localhost") {
      console.log("localhost detected, using emulated backend!");
      firebase.functions().useFunctionsEmulator("http://localhost:5001");

      // Annoyingly function emulation doesn't work if you specify a region
      this.createPaymentPage = firebase.functions().httpsCallable('createPaymentPage');
      this.createPaymentLink = firebase.functions().httpsCallable('createPaymentLink');
      this.getPaymentStatus = firebase.functions().httpsCallable('getPaymentStatus');
      this.getPaymentInfo = firebase.functions().httpsCallable('getPaymentInfo');
      this.updateProfileImage = firebase.functions().httpsCallable('updateProfileImage');
      this.getAllPaymentsForUser = firebase.functions().httpsCallable('getAllPaymentsForUser');
      this.getPaymentsBetweenUsers = firebase.functions().httpsCallable('getPaymentsBetweenUsers');
      this.storeRequest = firebase.functions().httpsCallable('storeRequest');
      this.getRequests = firebase.functions().httpsCallable('getRequests');
      this.markRequestDone = firebase.functions().httpsCallable('markRequestDone');
      // *** If you add something here ^ don't forget to add it below too! ***
      
      this.db.settings({
        host: "localhost:8080",
        ssl: false
      });
    } else {
      this.createPaymentPage = firebase.app().functions("europe-west2").httpsCallable('createPaymentPage');
      this.createPaymentLink = firebase.app().functions("europe-west2").httpsCallable('createPaymentLink');
      this.getPaymentStatus = firebase.app().functions("europe-west2").httpsCallable('getPaymentStatus');
      this.getPaymentInfo = firebase.app().functions("europe-west2").httpsCallable('getPaymentInfo');
      this.updateProfileImage = firebase.app().functions("europe-west2").httpsCallable('updateProfileImage');
      this.getAllPaymentsForUser = firebase.app().functions("europe-west2").httpsCallable('getAllPaymentsForUser');
      this.getPaymentsBetweenUsers = firebase.app().functions("europe-west2").httpsCallable('getPaymentsBetweenUsers');
      this.storeRequest = firebase.app().functions("europe-west2").httpsCallable('storeRequest');
      this.getRequests = firebase.app().functions("europe-west2").httpsCallable('getRequests');
      this.markRequestDone = firebase.app().functions("europe-west2").httpsCallable('markRequestDone');
    }
  }

  signInConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: [
          'email',
        ],
      },
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  doSignOut = () => this.auth.signOut();
}
export default Firebase;