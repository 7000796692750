export const LANDING = '/';
export const ABOUT = '/about';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signou';
export const ACCOUNT = '/acc';
export const PAYMENT_REQUESTED = '/payreq';
export const HELP = '/help';
export const PRIVACY = '/priv';
export const FAQ = '/faq';
export const LANDING_ALT = '/land'; // QR code for nimble.cash/ sends to nimble.ca, at least on android, so use this instead.
// Make sure these are all <7 letters so they don't conflict with user pages