import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import StepHeader from './StepHeader';
import providers from '../../assets/account-providers.json';
import searchIcon from '../../assets/search_24px.svg'

const config = {
  TLAuthDomain: process.env.REACT_APP_TRUELAYER_AUTH_DOMAIN,
  TLClientId: process.env.REACT_APP_TRUELAYER_CLIENT_ID,
  host: process.env.REACT_APP_AUTH_DOMAIN,
  // Only include mock bank in the sandbox.
  includeMockBank: (process.env.REACT_APP_TRUELAYER_AUTH_DOMAIN === "https://auth.truelayer-sandbox.com")
};

function filterValidProviders(providers) {
  return providers.filter(provider =>
    provider.scopes.includes("info") &&
    provider.scopes.includes("accounts") &&
    provider.country === "uk" &&
    (config.includeMockBank || provider.provider_id !== "mock")
  );
}

class SelectBank extends Component {
  constructor() {
    super();
    this.allProviders = filterValidProviders(providers.sort((a, b) => a.display_name.localeCompare(b.display_name)));
    this.state = {
      providers: this.allProviders,
    }
    this.getAuthLink = this.getAuthLink.bind(this);
    this.searchProviders = this.searchProviders.bind(this);
  }

  getAuthLink(provider_id) {
    var host;
    if (window.location.hostname === "localhost") {
      host = "http://" + window.location.host;
    } else {
      host = "https://" + config.host;
    }

    return (config.TLAuthDomain + "/?" +
    "response_type=code&" +
    "client_id=" + config.TLClientId + "&" +
    "scope=info%20accounts&" +
    "state=" + this.props.state + "&" +
    "redirect_uri=" + host + "/signup&" +
    "providers=uk-ob-all%20uk-oauth-all" +
    (config.includeMockBank ? "%20uk-cs-mock" : "") +
    "&provider_id=" + provider_id)
  }

  searchProviders(event) {
    var filteredProviders = this.allProviders.filter(provider =>
      provider.display_name.toLowerCase().includes(event.target.value.toLowerCase())
    )

    this.setState({providers: filteredProviders});
  }

  render() {
    return(
      <div className="bank-selection-page">
        <StepHeader title="Select your bank" handleBack={this.props.handleBack}/>
        <div className="bank-search">
          <img className="bank-search-icon" src={searchIcon} alt=""></img>
          <input className="bank-search-input" type="text" onChange={this.searchProviders} placeholder="Search"></input>
        </div>
        <div className="bank-selection">
          {this.state.providers.map(provider => (
            <a key={provider.provider_id} className="bank-selection-card" href={this.getAuthLink(provider.provider_id)}>
              <img className="bank-logo-img" src={provider.logo_url} alt={provider.display_name}></img>
              <div className="bank-name">
                {provider.display_name}
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

// Require users be signed in to see this page
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(SelectBank));