
import React from "react";
import { withRouter } from "react-router-dom";
import Menu from 'react-burger-menu/lib/menus/slide'
import { Link } from "react-router-dom";

import { withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import logoIcon from "../../assets/Group 92.svg"
import accountIcon from "../../assets/person_24px.svg"
import securityIcon from "../../assets/https_24px_rounded.svg"
import helpIcon from "../../assets/live_help_24px.svg"
import aboutIcon from "../../assets/info_24px.svg"
import signOutIcon from "../../assets/red_arrow_back_24px.svg"
import faqIcon from "../../assets/faq-icon.svg"

class Burger extends React.Component {
  constructor() {
    super();
    this.state = {
      pageId : null,
    }
    this.goToPaymentPage = this.goToPaymentPage.bind(this);
  }

  componentDidUpdate() {
    if (this.state.pageId) {
      return
    }
    if (this.props.pageId) {
      this.setState({pageId: this.props.pageId})
    } else if (this.props.authUser !== null)  {
      this.props.firebase.db.collection("paymentPages").where("user", "==", this.props.authUser.uid).get()
      .then(result => {
        if (result.docs.length > 0) {
          this.setState({pageId: result.docs[0].id});
        }
      })
      .catch(error => {
        console.log('Error getting page for user', this.props.authUser.uid, error);
      });
    }
  }
  
  goToPaymentPage() {
    if (this.state.pageId) {
      this.props.history.push(this.state.pageId);
      // Have to refresh because these match the same Route so don't reload automatically
      window.location.reload();
    }
  }


  render() {
    if (this.props.authUser === null) {
      return (<Link className="sign-in-button" to={ROUTES.SIGN_IN}>Sign in</Link>)
    }

    return (
      <Menu right disableAutoFocus>
        <div className="bm-header">
          <img className="bm-header-icon"  src={logoIcon} alt=""></img>
          {this.props.authUser.displayName}
        </div>
        <button onClick={this.goToPaymentPage}>
          <img className="bm-item-icon" src={accountIcon} alt=""></img>
          Account
        </button>
        <Link  to={ROUTES.FAQ}>
          <img className="bm-item-icon" src={faqIcon} alt=""></img>
          FAQs
        </Link>
        <Link  to={ROUTES.PRIVACY}>
          <img className="bm-item-icon" src={securityIcon} alt=""></img>
          Privacy & Security
        </Link>
        <Link to={ROUTES.HELP}>
          <img className="bm-item-icon" src={helpIcon} alt=""></img>
          Help & Support
        </Link>
        <Link to={ROUTES.ABOUT}>
          <img className="bm-item-icon" src={aboutIcon} alt=""></img>
          About
        </Link>
        <button className="bm-sign-out" onClick={this.props.firebase.doSignOut}>
          <img className="bm-item-icon" src={signOutIcon} alt=""></img>
            Sign Out
        </button>
      </Menu>
    );
  }
}

export default withAuthentication(withFirebase(withRouter(Burger)));