import React from 'react';

import friendsImg from "../../assets/undraw_pleasant_surprise_5sjy 1.svg"

export default () => (
  <div className="profile-page-tab-placeholder">
    <div>
      <img src={friendsImg} alt=""></img>
      <br/>
      <br/>
      <div>Ability to add friends coming soon!</div>
    </div>
  </div>
);