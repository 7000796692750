import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logoImg from "../../assets/logo.svg";
import BurgerMenu from '../BurgerMenu';

class Help extends Component {
  render() {
    return (
      <div className="page-container help-page">
        <BurgerMenu/>
        <button className="help-home-button" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
          <img className="help-home-button-logo" src={logoImg} alt="home"></img>
        </button>
        <div className="help-header">
          About
        </div>
        <div className="help-body">
          <p>
          At Nimble we want to make money easier for everyone. Using Nimble you can pay your friends easily and keep track of who owes you, so that you can focus on the more important things in life.
          <br/><br/>
          Nimble is provided by Nimble Cash Ltd,  registered in the UK. 
          <br/><br/>
          We’re based in London. We’d love to hear from you - feel free to reach out to us at <a href = "mailto: hello@nimble.cash">hello@nimble.cash</a>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Help);