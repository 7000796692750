import React, { Component } from 'react';

import friendsIcon from "../../assets/friends-icon.svg"
import historyIcon from "../../assets/history-icon.svg"
import pendingIcon from "../../assets/pending-icon.svg"

import { withFirebase } from '../Firebase';
import { withAuthorization } from "../Session";

import BurgerMenu from '../BurgerMenu';

import ProfileInfo from './ProfileInfo';
import PaymentHistory from './PaymentHistory';
import Friends from './Friends';
import Requests from './Requests';
import EnterAmount from './EnterAmount';
import SendAction from './SendAction';
import ShareRequestLink from './ShareRequestLink';

const profileTabs = {
  FRIENDS: 0,
  HISTORY: 1,
  REQUESTS: 2,
}

const requestSteps = {
  INITIAL: 0,
  ENTER_AMOUNT: 1,
  SHARE: 2,
}

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: profileTabs.HISTORY,
      requestStep: requestSteps.INITIAL,
      request: null,
    };
    this.sharePageLink = this.sharePageLink.bind(this);
    this.handleEnterAmountSubmit = this.handleEnterAmountSubmit.bind(this);
    this.handleRequestResend = this.handleRequestResend.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  // TODO: Identical code in PaymentPage
  sharePageLink() {
    var data = {
      title: "Pay " +  this.props.ownerInfo.displayName + " with Nimble",
      text: 'Pay quickly and securely with Nimble, no sign-up needed.',
      url: window.location.protocol + '//' + window.location.host + '/' + this.props.pageId,
    }
    this.share(data).catch((error) => {
      console.error('Error sharing page link: ', error);
    });
  }

  shareRequestLink(requestId, amountString, message) {
    var data = {
      title: "Pay " +  this.props.ownerInfo.displayName + " with Nimble",
      text: this.props.ownerInfo.displayName + ' requests £' + amountString + ' for ' + message + '\n'  +
       'Pay quickly and securely with Nimble, no sign-up needed.',
      url: window.location.protocol + '//' + window.location.host + '/' + this.props.pageId +
        "?req=" + encodeURIComponent(requestId),
    }
    return this.share(data)
  }

  storeRequest(amountInPence, message) {
    var request = {
      "amountInPence": amountInPence,
      "message": message
    }
    return this.props.firebase.storeRequest(request)
    .then(result => {
      var requestId = result.data;
      return requestId;
    });
  }

  // TODO: Identical code in PaymentPage
  share(data) {
    if (navigator.share) {
      return navigator.share(data)
        // TODO: Record on the request whether it was successfully shared?
        .then(() => console.log('Successful share'))
    } else {
      // TODO: handle browsers other than Chrome/Samsung on Android and Safari on iOS
      return Promise.reject(new Error("Sharing not supported. Attempted to share: " + JSON.stringify(data)));
    }
  }

  convertAmountInPoundsStringToAmountInPenceInt(amountInPoundsString) {
    var decimalParts = (amountInPoundsString + "00").split(".");
    if (decimalParts.length === 1) {
      // There's no decimal point, we've already add two zeros to multiply by 100, so we're done.
      return Number.parseInt(decimalParts[0]);
    }
    if (decimalParts.length === 2) {
      if (decimalParts[1].length > 4) {
        throw Error("Not a whole number of pence: ", amountInPoundsString)
      }
      var amountInPenceString = decimalParts[0] + decimalParts[1].slice(0,2) + "." + decimalParts[1].slice(2);
      return Number.parseInt(amountInPenceString);
    }
    throw Error("Could not parse amount: ", amountInPoundsString);
  }

  handleEnterAmountSubmit(event) {
    event.preventDefault();
    var amountString = event.target.amountInPounds.value.toString();
    var amountInPence = this.convertAmountInPoundsStringToAmountInPenceInt(amountString);
    var message = event.target.message.value;
    // TODO add spinner or send to separate page while we write request to DB.
    return this.storeRequest(amountInPence, message)
    .then(requestId => this.setState({
      requestStep: requestSteps.SHARE,
      request: {
        requestId: requestId,
        requester: this.props.ownerInfo.displayName,
        amountInPence: amountInPence,
        amountString: amountString,
        message: message,
      }
    })).then(() => this.props.refreshRequests());
  }

  handleShare() {
    return this.shareRequestLink(this.state.request.requestId, this.state.request.amountString, this.state.request.message)
    .then(() => {
      this.setState({activeTab: profileTabs.REQUESTS, requestStep: requestSteps.INITIAL, request: null});
    }).catch((error) => {
      console.error('Error sharing request: ', error);
    });
  }

  handleRequestResend(requestId, amountInPence, message) {
    var amountString = (amountInPence / 100).toFixed(2);
    return this.shareRequestLink(requestId, amountString, message)
    .catch(error => console.error(error))
  }

  render() {
    switch (this.state.requestStep) {
      case requestSteps.INITIAL:
        const activeTab = this.state.activeTab;
        return (
          <div className="profile-page-container">
            <BurgerMenu pageId={this.props.pageId}/>
            <div className="profile-card">
              <ProfileInfo ownerInfo={this.props.ownerInfo} signedInUserOwnsThisPage={true} shareLink={() => this.sharePageLink()}/>
              <div className="profile-card-tabs">
                <button className={"profile-card-tab-button" + (activeTab === profileTabs.FRIENDS ? "-active" : "")}
                        onClick={() => this.setState({activeTab: profileTabs.FRIENDS})}>
                   <img className="profile-card-tab-button-icon" src={friendsIcon} alt=""></img>
                   <div className="profile-card-tab-button-text">Friends</div>
                </button>
                <button className={"profile-card-tab-button" + (activeTab === profileTabs.HISTORY ? "-active" : "")}
                        onClick={() => this.setState({activeTab: profileTabs.HISTORY})}>
                  <img className="profile-card-tab-button-icon" src={historyIcon} alt=""></img>
                  <div className="profile-card-tab-button-text">History</div>
                </button>
                <button className={"profile-card-tab-button" + (activeTab === profileTabs.REQUESTS ? "-active" : "")}
                        onClick={() => this.setState({activeTab: profileTabs.REQUESTS})}>
                  <img className="profile-card-tab-button-icon" src={pendingIcon} alt=""></img>
                  <div className="profile-card-tab-button-text">Requests</div>
                </button>
              </div>
            </div>
            {activeTab === profileTabs.FRIENDS ? <Friends/> : ""}
            {activeTab === profileTabs.HISTORY ? <PaymentHistory owner={this.props.ownerInfo.ownerId} paymentHistory={this.props.paymentHistory} pageId={this.props.pageId}/> : ""}
            {activeTab === profileTabs.REQUESTS ? <Requests paymentRequests={this.props.paymentRequests} handleRequestResend={this.handleRequestResend} markRequestDone={this.props.markRequestDone}/> : ""}
            <button className="profile-page-primary-button" onClick={() => this.setState({requestStep: requestSteps.ENTER_AMOUNT})}>Request money</button>
          </div>);
      case requestSteps.ENTER_AMOUNT:
        return (<EnterAmount
          sendAction={SendAction.REQUEST}
          goBack={() => this.setState({requestStep: requestSteps.INITIAL})}
          handleSubmit={this.handleEnterAmountSubmit}/>);
      case requestSteps.SHARE:
        return (<ShareRequestLink
          ownerInfo={this.props.ownerInfo}
          requestInfo={this.state.request}
          goBack={() => this.setState({requestStep: requestSteps.INITIAL})}
          handleSubmit={this.handleShare}/>);
      default:
        console.error("Unexpected request step", this.state.requestStep);
    }
    
  }
}

// Only signed in users
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(ProfilePage));