import React, { Component } from 'react';
import SignInScreen from '../SignIn/SignInScreen';
import StepHeader from './StepHeader';


class CreateAccount extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="page-container">
        <StepHeader
          title="Create account"
          handleBack={this.props.handleBack}
        />
        <div className="page-body">
          <SignInScreen handleSignIn={this.props.handleSignIn} />
        </div>
      </div>
    );
  }
}

export default CreateAccount;