import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import cancelIcon from "../../assets/cancel-icon.svg"
import popupImage from "../../assets/undraw-connection.svg"


class TrustPopUp extends Component {
  render() {
    return (
      <div className="info-popup">
        <div className="info-popup-header">
          <button className="info-popup-close-button" onClick={this.props.close}>
          <img className="info-popup-close-button-img" src={cancelIcon} alt="close"></img>
          </button>
        </div>
        <img className="info-popup-img" src={popupImage} alt=""></img>
        <div className="info-popup-body">
          Nimble is the safe, secure way to pay your friends. It works just like a bank transfer - but easier!
          <br/><br/>
          Just choose your bank, and we’ll take you to their app to approve the payment. We’ve verified the name and account details so you don’t need to worry about typing it all in.
          <br/><br/>
          <a href={ROUTES.FAQ}>Read our FAQs</a>
        </div>
      </div>
    );
  }
}

export default withRouter(TrustPopUp);