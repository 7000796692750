import React from 'react';
import { withRouter } from 'react-router-dom';

import shareIconPrimary from "../../assets/unnamed-icon.svg"; // Can't call it "share-icon" or it get's adblocked!
import shareIconSecondary from "../../assets/unnamed-icon-grey.svg"; // Can't call it "share-icon" or it get's adblocked!
import defaultUserIcon from "../../assets/person_24px.svg";


const ProfileInfo = ({ownerInfo, signedInUserOwnsThisPage, shareLink}) => (
  <div className="profile-card-main">
    <div className="profile-img-container">
      <img className="profile-img" src={ownerInfo.imageUrl} alt={ownerInfo.displayName}></img>
      {/* TODO: edit profile img button*/}
    </div>
    <div className="profile-info-container">
      <h2 className="profile-name">
        {ownerInfo.displayName}
        <img className="profile-info-bank-logo" src={ownerInfo.provider.logo_uri ? ownerInfo.provider.logo_uri : defaultUserIcon} alt=""></img>
      </h2>
      <button className={"profile-share-" + (signedInUserOwnsThisPage ? "primary" : "secondary")} onClick={shareLink}>
        <img className="profile-share-icon" src={signedInUserOwnsThisPage ? shareIconPrimary : shareIconSecondary} alt=""></img>
        Share profile
      </button>
    </div>
  </div>
);

export default withRouter(ProfileInfo);