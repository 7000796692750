import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';

import logoImg from "../../assets/logo.svg"
import logoTextImg from "../../assets/logo-text.svg"

class SignInScreenBase extends Component {
  componentDidMount() {
    if (this.props.authUser) {
      this.props.handleSignIn(this.props.authUser)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authUser !== this.props.authUser && this.props.authUser) {
      this.props.handleSignIn(this.props.authUser)
    }
  }

  render() {
    return (
      <div className="sign-in-screen">
        <div className="big-logo-with-logotext">
            <div className="big-logo">
              <img src={logoImg} alt=""></img>
            </div>
            <div className="big-logotext">
              <img src={logoTextImg} alt=""></img>
            </div>
          </div>
        <StyledFirebaseAuth
          className="sign-in-options"
          uiConfig={this.props.firebase.signInConfig}
          firebaseAuth={this.props.firebase.auth}
        />
      </div>
    );
  }
}

const SignInScreen = withAuthentication(withFirebase(SignInScreenBase));

export default SignInScreen;