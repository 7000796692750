import React, { Component } from 'react';
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import StepHeader from './StepHeader';

import linkIcon from '../../assets/link-icon.svg';
import tickIcon from '../../assets/green-tick-icon.svg';
import crossIcon from '../../assets/red-cross-icon.svg';

const availablility = {
  CHECKING: 0,
  SUCCESS: 1,
  FAILURE: 2,
}

class ChooseUrl extends Component {
  constructor() {
    super();
    this.state = {
      pageId: "",
      validLength: false,
      validCharacters: false,
      validAvailability: availablility.CHECKING,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var initialPageIdSuggestion = this.props.authUser.displayName.toLowerCase().replace(/[^a-z0-9]/g,"");
    this.setState({pageId: initialPageIdSuggestion}, () => this.checkPageValidity(initialPageIdSuggestion));
  }

  isValidLength(pageId) {
    return /^.{7,30}$/.test(pageId)
  }

  isValidCharacters(pageId) {
    return /^[a-z0-9]+$/.test(pageId);
  }

  isValid() {
    return this.state.validLength && this.state.validCharacters && (this.state.validAvailability === availablility.SUCCESS);
  }

  handleChange(event) {
    event.preventDefault();
    var pageId = event.target.value.toLowerCase();
    this.checkPageValidity(pageId);
  }

  checkPageValidity(pageId) {
    var validLength = this.isValidLength(pageId);
    var validCharacters = this.isValidCharacters(pageId)

    this.setState({
      pageId: pageId,
      validLength: validLength,
      validCharacters: validCharacters,
      validAvailability: availablility.CHECKING, 
    });

    if (validLength && validCharacters) {
      this.checkAvailability(pageId);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.isValid())
    this.props.handleConfirm(this.state.pageId)
    // TODO proper validation with error messages, live UI etc.
  }

  checkAvailability(pageId) {
    this.props.firebase.db.collection("paymentPages").doc(pageId).get()
    .then(doc => this.setState({pageId: pageId, validAvailability: doc.exists ? availablility.FAILURE : availablility.SUCCESS}))
    .catch(error => {
      console.log('Error getting page ', pageId, error);
      return this.setState({pageId: pageId, validAvailability: availablility.FAILURE});
    });
  }

  render() {
    return (
        <div className="page-container">
            <StepHeader
              title="Choose your page"
              handleBack={this.props.handleBack}
            />
            <form className="choose-url-main-container" onSubmit={this.handleSubmit} onChange={this.handleChange} autoComplete="off">
              <div>{/* spacer */}</div>
              <div className="choose-url-body-text">
                Nimble creates a webpage where anyone can pay you.
              </div>
              <div className="choose-url-inputs">
                <label className={"choose-url-label"}>
                  <img className={"choose-url-link-img"} src={linkIcon} alt=""></img>
                  nimble.cash/
                  <input className={"choose-url-input"}
                    autoFocus={true}
                    defaultValue={this.state.pageId}/>
                </label>
                <div>{this.state.validLength ?
                  <img className="choose-url-validity-icon" src={tickIcon} alt=""></img>
                  : <img className="choose-url-validity-icon" src={crossIcon} alt=""></img>}
                  Between 7 and 30 characters
                </div>
                <div>{this.state.validCharacters ?
                  <img className="choose-url-validity-icon" src={tickIcon} alt=""></img>
                  : <img className="choose-url-validity-icon" src={crossIcon} alt=""></img>}
                  Only letters or numbers
                </div>
                {this.state.validLength && this.state.validCharacters &&
                <div>
                  {this.state.validAvailability ===  availablility.CHECKING &&
                    <div className="spinner choose-url-validity-icon"><Spinner animation="border" size="sm"/></div>}
                  {this.state.validAvailability ===  availablility.SUCCESS  && <img className="choose-url-validity-icon" src={tickIcon} alt=""></img>}
                  {this.state.validAvailability ===  availablility.FAILURE  && <img className="choose-url-validity-icon" src={crossIcon} alt=""></img>}
                  URL available
                </div>}
              </div>
              <div>{/* spacer */}</div>
              <input
                className={this.isValid() ? "primary-button"  : "disabled-button"}
                type="submit"
            value={"Next"} />
            </form>
        </div>
    );
  }
}

// Require users be signed in to see this page
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(ChooseUrl));
