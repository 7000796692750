import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logoImg from "../../assets/logo.svg";
import BurgerMenu from '../BurgerMenu';

class Help extends Component {
  render() {
    return (
      <div className="page-container help-page">
        <BurgerMenu/>
        <button className="help-home-button" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
          <img className="help-home-button-logo" src={logoImg} alt="home"></img>
        </button>
        <div className="help-header">
          FAQs
        </div>
        <div className="help-body">
          <div className="help-collabsible-item">
            <input id="help-collapsible-label-1" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-1" className="help-collapsible-label-toggle">What is Nimble?</label>
            <div className="help-collapsible-content">
              <p>
                Nimble is the easy way to pay your friends. With a Nimble account, anyone can pay you in just a few clicks, whether or not they have a Nimble Account. The money goes straight into your account, just like a bank transfer - Nimble never touches your money.
              </p>
            </div>
          </div>
          
          <div className="help-collabsible-item">
            <input id="help-collapsible-label-2" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-2" className="help-collapsible-label-toggle">How does making a payment work?</label>
            <div className="help-collapsible-content">
              <p>
              Every time you want make a payment, we’ll take you to your bank’s app so that you can confirm it. Nimble can never move money without your explicit permission.
              </p>
            </div>
          </div>
          
          <div className="help-collabsible-item">
            <input id="help-collapsible-label-3" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-3" className="help-collapsible-label-toggle">How do I pay someone?</label>
            <div className="help-collapsible-content">
              <p>
              You can pay someone in just a few clicks: we just take you to your bank’s app where you can approve the payment. Your bank will check every time you’d like to make a payment, so no money will every move without your permission.
              <br/><br/>
              We’ve securely verified your friend’s name and account details with their bank, so that you can make sure you’re always paying who you intend. No need to worry about typing in account numbers and sort codes again!
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-4" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-4" className="help-collapsible-label-toggle">How do my friends pay me?</label>
            <div className="help-collapsible-content">
              <p>
              Send your friends to your Nimble page (which looks like nimble.cash/yourname). Or, you can create a request and send it to a friend. We’ll keep track of the requests you make so you don’t have to worry about them. (You can find them on your profile page after you sign in.)
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-5" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-5" className="help-collapsible-label-toggle">Do I need a Nimble account to pay someone?</label>
            <div className="help-collapsible-content">
              <p>
              No! You can pay a friend without a Nimble account - we’ll just direct you to whichever bank you prefer to approve the payment. 

How does setting up a Nimble account work?
When you set up a Nimble account, we ask for your permission to collect some details from your bank account with us: your name, account number and sort code. Your bank doesn’t share any other information. 

We collect this just so that we can set up a page for your friends to pay you. To allow you to share this information, we’ll take you to your bank’s app where you can approve us to have access, through our partner TrueLayer. 
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-6" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-6" className="help-collapsible-label-toggle">How are payments processed?</label>
            <div className="help-collapsible-content">
              <p>
              When you instruct you bank to make a payment through Nimble, it’s processed by your bank just like a bank transfer, and the money goes straight from your account into theirs. The technology to do this is thanks to the UK’s Open Banking policies, and is provided by TrueLayer, an FCA-regulated Payment Initiation Service Provider (license number 793171).
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-7" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-7" className="help-collapsible-label-toggle">How much does Nimble cost?</label>
            <div className="help-collapsible-content">
              <p>
              It’s free!
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-8" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-8" className="help-collapsible-label-toggle">Is it secure?</label>
            <div className="help-collapsible-content">
              <p>
              Yes! Payments can only happen if you personally approve each one, and TrueLayer secures the connection to the bank with bank-grade encryption. We never sell your data to any third parties.
              </p>
            </div>
          </div>

          <div className="help-collabsible-item">
            <input id="help-collapsible-label-9" className="help-collapsible-toggle" type="checkbox"></input>
            <label for="help-collapsible-label-9" className="help-collapsible-label-toggle">How can I contact you?</label>
            <div className="help-collapsible-content">
              <p>
                For any issues, just reach out to us at <a href = "mailto: support@nimble.cash">support@nimble.cash</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default withRouter(Help);