import React from 'react';

import { withUserAgent } from "react-useragent";

import * as ROUTES from '../../constants/routes';
import logoWithTextImg from "../../assets/logo-with-text.svg";
import desktopImg from "../../assets/desktop-img.svg";

var QRCode = require('qrcode.react');

const withDesktopCheck = Component => {
  class WithDesktopCheck extends React.Component {
    render() {
      if (this.props.ua.mobile) {
        return(<Component {...this.props} />);
      } else {
        var redirect_url
        // QR code of 'nimble.cash' sends to 'nimble.ca', at least on android, so
        // use alternate landing page path
        if (window.location.pathname === "/" || window.location.pathname === "") {
          redirect_url = window.location.origin + ROUTES.LANDING_ALT;
        } else {
          redirect_url = window.location.href
        }

        return(
          <div className="desktop-page">
            <div className="desktop-left">
              <div className="desktop-logo">
                <img src={logoWithTextImg} alt=""></img>
              </div>
              <ul className="desktop-bullets">
                <li className="desktop-bullet">
                  Payments, now
                </li>
                <li className="desktop-bullet">
                  Fast, free, secure transfers from any bank to any bank
                </li>
                <li className="desktop-bullet">
                  <p><b>Nothing to download.</b> We create a webpage where anyone can pay you.</p>
                </li>
              </ul>
              <div className="desktop-qr-code-container">
                <QRCode className="desktop-qr-code" value={redirect_url} size={125}/>
                <div className="desktop-qr-code-container-text">
                  Scan with your phone to get started
                </div>
              </div>
            </div>
            <div className="desktop-right">
              <img src={desktopImg} alt=""></img>
            </div>
          </div>
          
        );
      }
    }
  }
  return withUserAgent(WithDesktopCheck);
};

export { withDesktopCheck };
