import React, { Component } from 'react';
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';

import SignInScreen from '../SignIn/SignInScreen';

class AccountCreated extends Component {

  componentDidMount() {
    if (this.props.authUser) {
      this.createPaymentPage(this.props.authUser);
    }
  }

  createPaymentPage(authUser) {
    this.props.firebase.createPaymentPage({
      pageId: this.props.pageId,
      exchangeCode: this.props.exchangeCode,
      userInfo : {
        displayName: authUser.displayName,
        email: authUser.email,
        phoneNumber: authUser.phoneNumber,
        imageUrl: authUser.photoURL,
      },
    }).then(result => {
        return result.data
    }).then(pageCreatedResponse => {
      this.props.handleAccountSaved()
    }).catch(error => {
      // Maybe the page was refreshed and the page has already been successfully created.
      // If the page exists, proceed.
      this.props.firebase.db.collection("paymentPages").doc(this.props.pageId).get().then(doc => {
        if (doc.exists && doc.data().user === authUser.uid) {
          this.props.handleAccountSaved();
        } else {
          console.error("Payment creation failed", error)
          // TODO: error page, send people back to last step
        }
      })
    });
  }

  render() {
    if (!this.props.authUser) {
      return (
        <div className="page-container">
          <div className="page-body">
            <p className="sign-in-again-text">Please sign in again to finish making your page.</p>
            <SignInScreen handleSignIn={(authUser) => this.createPaymentPage(authUser)} />
          </div>
        </div>
      );
    }

    return(
      <div className="spinner-page"><div className="spinner"><Spinner animation="border"/></div></div>
    );
  }
}

export default withAuthentication(withFirebase(AccountCreated));
