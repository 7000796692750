import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { withFirebase } from '../Firebase';
import { withAuthentication } from "../Session";

import checkMarkIcon from "../../assets/check-mark-icon.svg"
import logoImg from "../../assets/logo.svg"
import cancelIcon from "../../assets/cancel-icon.svg"


class RequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
    };
  }

  render() {
      // TODO: this is all copied from the payment page. Needs design, and if we go with this, needs sharing code.
    return (
      <div className="page-container">
        <button className="enter-amount-cancel-button" onClick={this.props.goBack}>
          <img src={cancelIcon} alt=""></img>
        </button>
        {this.state.showPopup &&
          <div className="info-popup">
            <div className="info-popup-header">
              <button className="info-popup-close-button" onClick={() => this.setState({showPopup: false})}>
                <img className="info-popup-close-button-img" src={cancelIcon} alt=""></img>
              </button>
            </div>
            <div className="info-popup-body">
              Nimble is the safe, secure way to pay your friends. It works just like a bank transfer - but easier!
              <br/><br/>
              Just choose your bank, and we’ll take you to their app to approve the payment. We’ve verified the name and account details so you don’t need to worry about typing it all in.
            </div>
          </div>
        }
        <div className="request-page-header">
             <img className="enter-amount-check-mark-img" src={checkMarkIcon} alt=""></img>
             <img className="enter-amount-bank-provider-logo" src={this.props.ownerInfo.provider.logo_uri} alt={this.props.ownerInfo.provider.display_name}></img>
             {"Verified account: " + this.props.ownerInfo.nameOnAccount}
        </div>
        <div className="request-page-body">
          <div className="requester-photo-container">
            <img className="profile-img" src={this.props.ownerInfo.imageUrl} alt={this.props.ownerInfo.displayName}></img>
          </div>
          <div>
            <b>{this.props.ownerInfo.displayName}</b> requests
          </div>
          <div className="request-amount">
            {"£" + (Number.parseInt(this.props.requestInfo.amountInPence) / 100).toFixed(2)}
          </div>
          <div className="request-message">
            {this.props.requestInfo.message}
          </div>
        </div>
        <button className="primary-button" onClick={this.props.handleSubmit}>Share request link</button>
        <button className="tertiary-button"
          onClick={() => this.setState({showPopup: !this.state.showPopup})}>
          <img className="request-page-button-logo" src={logoImg} alt=""></img>
          How does this work?
        </button>
      </div>);
  }
}

export default withAuthentication(withFirebase(withRouter(RequestPage)));