import React, { Component }from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import BurgerMenu from '../BurgerMenu';

import logoImg from "../../assets/logo.svg"
import logoTextImg from "../../assets/logo-text.svg"
import tickIcon from "../../assets/tick-icon.svg"
import paymentFailedIcon from "../../assets/warning-icon.svg"
import paymentPendingIcon from "../../assets/clock-icon.svg"


 const paymentStatuses = {
  LOADING: 0,
  SUCCESS: 1,
  FAIL: 2,
  STILL_PENDING: 3,
}

class PaymentRequested extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatus : paymentStatuses.LOADING,
      imageUrl: "",
    };
  }

  getUsersPaymentPage(uid) {
    return this.props.firebase.db.collection("paymentPages").where("user", "==", uid)
    .get()
    .then(result => result.docs[0])
  }

  getUserPageId(uid) {
    return this.getUsersPaymentPage(uid).then(pageInfo => pageInfo.id);
  }

  getUserImageUrl(uid) {
    return this.getUsersPaymentPage(uid).then(pageInfo => pageInfo.get("imageUrl"));
  }

  componentDidMount() {
    const paymentId = queryString.parse(this.props.location.search).payment_id;
    this.props.firebase.getPaymentInfo(paymentId)
    .then(result => this.setState({paymentInfo: result.data}))
    .then(() => this.getUserImageUrl(this.state.paymentInfo.beneficiaryUid))
    .then(imageUrl => this.setState({imageUrl: imageUrl}))
    .then(() => {
      if (this.props.authUser) {
        return this.getUserPageId(this.props.authUser.uid);
      } else {
        return null;
      }
    })
    .then(paymentPageId => this.setState({paymentPageId: paymentPageId}))
    .then(() => this.props.firebase.getPaymentStatus(paymentId))
    .then(result => {
      if (result.data.toLowerCase() === "executed") {
        this.setState({paymentStatus: paymentStatuses.SUCCESS})
      } else if (['cancelled', 'executed', 'failed', 'rejected'].includes(result.data.toLowerCase())) {
        this.setState({paymentStatus: paymentStatuses.FAIL})
      } else {
        this.setState({paymentStatus: paymentStatuses.STILL_PENDING})
      }
    })
    .catch(error => {
      console.error('Error getting payment status', paymentId, error);
    });
  }

  render() {
    if (!this.state.paymentInfo) {
      return (<div className="spinner-page"><div className="spinner"><Spinner animation="border"/></div></div>);
    }

    return (
      <div className="page-container">
        <BurgerMenu/>
        <button className="payment-requested-header" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
          <img className="payment-requested-header-logo" src={logoImg} alt=""></img>
          <img className="payment-requested-header-logo-text" src={logoTextImg} alt=""></img>
        </button>
        <div className="payment-requested-body">
          <div className="payment-requested-body-upper">
            <div className="payment-requested-photo-container">
              <img className="profile-img" src={this.state.imageUrl} alt=""></img>
            </div>

            {this.state.paymentStatus === paymentStatuses.LOADING &&
              <div className="payment-requested-status">
                <div className="payment-requested-status-spinner"><Spinner animation="border"/></div>
                <p>{"Paying "}<strong >{this.state.paymentInfo.beneficiaryName}</strong></p>
              </div>}
            {this.state.paymentStatus === paymentStatuses.SUCCESS &&
              <div className="payment-requested-status">
                <img className="payment-requested-status-icon" src={tickIcon} alt=""></img>
                <p>{"You paid "}
                <strong >{this.state.paymentInfo.beneficiaryName}</strong>
                </p>
              </div>}
            {this.state.paymentStatus === paymentStatuses.FAIL &&
              <div className="payment-requested-status">
                <img className="payment-requested-status-icon" src={paymentFailedIcon} alt=""></img>
                <p>{"Your payment to "}
                <strong>{this.state.paymentInfo.beneficiaryName}</strong>
                {" has failed to go through"}
                </p>
              </div>}
            {this.state.paymentStatus === paymentStatuses.STILL_PENDING &&
              <div className="payment-requested-status">
                <img className="payment-requested-status-icon" src={paymentPendingIcon} alt=""></img>
                <p>{"Your payment to "}
                <strong>{this.state.paymentInfo.beneficiaryName}</strong>
                {" is taking longer than expected"}
                </p>
            </div>}

            <div className="payment-requested-amount">
              {"£" + (Number.parseInt(this.state.paymentInfo.amountInPence) / 100).toFixed(2)}
            </div>
            <div className="payment-requested-message">
              {this.state.paymentInfo.message}
            </div>
          </div>
          <div className="payment-requested-body-lower">
          {this.props.authUser ?
            <div>Want to make your own requests?</div>
          :
            <div>Want to get paid this easily?</div>
          }
          </div>
        </div>
        {this.props.authUser ?
          <Link className="primary-button" to={this.state.paymentPageId}>Back to your page</Link>  
          :
          <Link className="primary-button" to={ROUTES.SIGN_UP}>Create your own Nimble page</Link>
        }
      </div>
    );
  }
}

export default withAuthentication(withRouter(withFirebase(PaymentRequested)));