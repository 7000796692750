import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Spinner from "react-bootstrap/Spinner";

import { withFirebase } from '../Firebase';
import { withAuthentication } from "../Session";

import PaymentPage from './PaymentPage';
import ProfilePage from './ProfilePage';
import RequestPage from './RequestPage';


// TODO: share code with validation in SignUp
function pageIdIsValid(pageId) {
  return (/[a-z0-9]{7,30}/.test(pageId));
}

class PaymentOrProfilePage extends Component {
  constructor(props) {
    super(props);
    const pageId = this.props.location.pathname.substring(1); // removes leading '/'
    const queryParams = queryString.parse(this.props.location.search);
    const requestId= queryParams.req;
    this.state = {
      pageId: pageId,
      requestId: requestId,
      ownerInfo: null, 
      requestInfo: null,
      doesNotExist: false,
      paymentHistory: null,
      paymentRequests: null,
    };
    this.markRequestDone = this.markRequestDone.bind(this);
    this.refreshRequests = this.refreshRequests.bind(this);
  }

  getPageIdOwnerInfo(pageId) {
    return this.props.firebase.db.collection("paymentPages").doc(pageId).get()
    .then(doc => {
      if (!doc.exists) {
        this.setState({doesNotExist: true});
        return null;
      } else {
        let info = doc.data();
        this.setState({
          ownerInfo: {
            exists: true,
            displayName: info.displayName,
            imageUrl: info.imageUrl,
            ownerId: info.user,
            nameOnAccount: info.nameOnAccount,
            provider: info.provider,
          },
        });
        return info.user;
      }
    })
    .catch(error => {
      console.log('Error getting page ', pageId, error);
      this.setState({doesNotExist: true});
      return null;
    });
  }

  getRequestInfo(requestId) {
    if (!requestId) {
      return null;
    }
    return this.props.firebase.db.collection("requests").doc(requestId).get()
    .then(doc => {
      if (!doc.exists) {
        this.setState({doesNotExist: true});
        return null;
      } else {
        let info = doc.data();
        this.setState({
          requestInfo: {
            requester: info.requester,
            amountInPence: info.amountInPence,
            message: info.message,
          },
        });
        return info.requester;
      }
    })
    .catch(error => {
      console.log('Error getting request ', requestId, error);
      this.setState({doesNotExist: true});
      return null;
    });
  }

  getPaymentHistory(ownerId) {
    if (this.props.authUser !== null && ownerId !== null) {
      if (this.props.authUser.uid === ownerId) {
        // This is the user's own page
        this.props.firebase.getAllPaymentsForUser().then(result => {
          this.setState({paymentHistory: result.data})
        });
      } else {
        // This is a logged in user on someone else's page
        this.props.firebase.getPaymentsBetweenUsers({otherUser: ownerId}).then(result => {
          this.setState({paymentHistory: result.data})
        })
      }
    } else  {
      this.setState({paymentHistory: []})
    }
  }

  getPaymentRequests(ownerId) {
    if (this.props.authUser !== null && ownerId !== null) {
      if (this.props.authUser.uid === ownerId) {
        // This is the user's own page
        this.props.firebase.getRequests().then(result => {
          this.setState({paymentRequests: result.data})
        });
      } else {
        // For now, not showing requests on other people's pages
      }
    } else  {
      this.setState({paymentRequests: []})
    }
  }

  markRequestDone(requestId) {
    this.setState({paymentRequests: null});
    return this.props.firebase.markRequestDone({requestId: requestId})
    .then(() => this.getPaymentRequests(this.state.ownerInfo.ownerId))
  }

  refreshRequests() {
    this.setState({paymentRequests: null});
    this.getPaymentRequests(this.state.ownerInfo.ownerId);
  }

  componentDidMount() {
    if (pageIdIsValid(this.state.pageId)) {
      var requesterUidPromise = this.getRequestInfo(this.state.requestId);
      var pageOwnerUidPromise = this.getPageIdOwnerInfo(this.state.pageId);
      Promise.all([requesterUidPromise, pageOwnerUidPromise])
      .then(([requesterId, ownerId]) => {
        if (requesterId && ownerId && (requesterId !== ownerId)) {
          this.setState({doesNotExist: true});
          console.error("Requester and page owner UIDs do not match");
        }
        return ownerId;
      })
      .then(ownerId => Promise.all([this.getPaymentHistory(ownerId), this.getPaymentRequests(ownerId)]));
    } else {
      this.setState({doesNotExist: true});
    }
  }

  render() {
    if (this.state.doesNotExist) {
      // TODO: proper message
      return (
        <div>
            <h1>Not Found</h1>
        </div>
      )
    }

    if (this.state.ownerInfo !== null) {
      if (this.props.authUser !== null && this.props.authUser.uid === this.state.ownerInfo.ownerId) {
        return (<ProfilePage
          ownerInfo={this.state.ownerInfo}
          pageId={this.state.pageId}
          paymentHistory={this.state.paymentHistory}
          paymentRequests={this.state.paymentRequests}
          markRequestDone={this.markRequestDone}
          refreshRequests={this.refreshRequests}
          />);
      } else if (this.state.requestInfo) {
        return (<RequestPage ownerInfo={this.state.ownerInfo} pageId={this.state.pageId} requestId={this.state.requestId} requestInfo={this.state.requestInfo}/>);
      } else {
        return (<PaymentPage ownerInfo={this.state.ownerInfo} pageId={this.state.pageId} paymentHistory={this.state.paymentHistory}/>);
      }
    }

    return (<div className="spinner-page"><div className="spinner"><Spinner animation="border"/></div></div>);
  }
}

export default withAuthentication(withRouter(withFirebase(PaymentOrProfilePage)));