import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logoImg from "../../assets/logo.svg";
import BurgerMenu from '../BurgerMenu';

class Help extends Component {
  render() {
    return (
      <div className="page-container help-page">
        <BurgerMenu/>
        <button className="help-home-button" onClick={()=> this.props.history.push(ROUTES.LANDING)}>
          <img className="help-home-button-logo" src={logoImg} alt="home"></img>
        </button>
        <div className="help-header">
          Help & Support
        </div>
        <div className="help-body">
          <p>
            If you have an issue, would like to delete your account, or just want to say hi, reach out to <a href = "mailto: support@nimble.cash">support@nimble.cash</a>
          </p>
        </div>
      </div>
      
    );
  }
}

export default withRouter(Help);