import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Landing from '../Landing';
import SignUp from '../SignUp';
import SignIn from '../SignIn';
import Account from '../Account';
import About from '../About';
import PaymentPage from '../PaymentPage';
import PaymentRequested from '../PaymentRequested';
import Help from '../Help'
import Privacy from '../Privacy'
import FAQs from '../FAQs'
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { withDesktopCheck } from '../Desktop';

const App = () => (
  <Router>
    <div className="app-background">
      {/* <Navigation />
      <hr/> */}
      <Switch>
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
        <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
        <Route exact path={ROUTES.ACCOUNT} component={Account} />
        <Route exact path={ROUTES.ABOUT} component={About} />
        <Route exact path={ROUTES.HELP} component={Help} />
        <Route exact path={ROUTES.PRIVACY} component={Privacy} />
        <Route exact path={ROUTES.FAQ} component={FAQs} />
        <Route exact path={ROUTES.PAYMENT_REQUESTED} component={PaymentRequested} />
        <Route exact path={ROUTES.LANDING_ALT} component={Landing} />
        <Route path="" component={PaymentPage} />
      </Switch>
    </div>
  </Router>
);

export default withDesktopCheck(withAuthentication(App))