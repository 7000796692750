import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import SignInScreen from './SignInScreen';
import { withFirebase } from '../Firebase';
import StepHeader from '../SignUp/StepHeader';


class SignIn extends Component {
  constructor() {
    super();
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleSignIn(authUser) {
    this.props.history.push(ROUTES.LANDING);
  }

  handleBack() {
    this.props.history.push(ROUTES.LANDING);
  }

  render() {
    if (!this.props.authUser) {
      return (
        <div className="page-container">
          <StepHeader title="Sign in" handleBack={this.handleBack}/>
          <div className="page-body">
          <SignInScreen handleSignIn={this.handleSignIn} />
          </div>
        </div>
      );
    }
  }
}

export default withFirebase(SignIn);