import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { withFirebase } from '../Firebase';
import { withAuthentication } from "../Session";

import BurgerMenu from '../BurgerMenu';
import EnterName from './EnterName';
import SelectBank from './SelectBank';
import TrustPopUp from '../TrustPopUp';
import ConfirmBank from './ConfirmBank';

import checkMarkIcon from "../../assets/check-mark-icon.svg"
import logoImg from "../../assets/logo.svg"

const steps = {
  INITIAL: 0,
  ENTER_NAME: 1,
  SELECT_BANK: 2,
  CONFIRM: 3,
}

class RequestPage extends Component {
  constructor(props) {
    super(props);
    this.handleEnterNameSubmit = this.handleEnterNameSubmit.bind(this);
    this.handleSelectBankSubmit = this.handleSelectBankSubmit.bind(this);

    this.state = {
      step: steps.INITIAL,
      showPopup: false,
      isPaymentLinkLoading: true,
    };
  }

  handleEnterNameSubmit(event) {
    event.preventDefault();
    this.setState({
      remitterName: event.target.from ? event.target.from.value : null,
      step: steps.SELECT_BANK,
    });
  }

  handleSelectBankSubmit(provider) {
    this.setState({remitterProvider: provider})
    this.props.firebase.createPaymentLink({
      pageId: this.props.pageId,
      amountInPence: this.props.requestInfo.amountInPence,
      reference: "Paid with Nimble",
      message: this.props.requestInfo.message,
      providerId: provider.id,
      requestId: this.props.requestId,
      remitterName: this.props.authUser ? this.props.authUser.displayName : this.state.remitterName ? this.state.remitterName : null,
    }).then(result => {
      this.setState({paymentLink: result.data.paymentLink, isPaymentLinkLoading: false})
    })
    //go to loading/click to confirm page
    this.setState({step: steps.CONFIRM})
  }

  render() {
    switch (this.state.step) {
      case steps.INITIAL:
        return (
          <div className="page-container">
            <BurgerMenu/>
            {this.state.showPopup && <TrustPopUp close={() => this.setState({showPopup: false})}/>}
            <div className="request-page-header">
                 <img className="enter-amount-check-mark-img" src={checkMarkIcon} alt=""></img>
                 <img className="enter-amount-bank-provider-logo" src={this.props.ownerInfo.provider.logo_uri} alt={this.props.ownerInfo.provider.display_name}></img>
                 {"Verified account: " + this.props.ownerInfo.nameOnAccount}
            </div>
            <div className="request-page-body">
              <div className="requester-photo-container">
                <img className="profile-img" src={this.props.ownerInfo.imageUrl} alt={this.props.ownerInfo.displayName}></img>
              </div>
              <div>
                <b>{this.props.ownerInfo.displayName}</b> requests
              </div>
              <div className="request-amount">
                {"£" + (Number.parseInt(this.props.requestInfo.amountInPence) / 100).toFixed(2)}
              </div>
              <div className="request-message">
                {this.props.requestInfo.message}
              </div>
            </div>
            {this.props.authUser ?
            <button className="primary-button"
              onClick={() => this.setState({
                paymentFrom: this.props.authUser.displayName,
                step: steps.SELECT_BANK
              })}>{"Pay £" + (Number.parseInt(this.props.requestInfo.amountInPence) / 100).toFixed(2)}</button>
            :
            <button className="primary-button"
              onClick={() => this.setState({
                step: steps.ENTER_NAME
              })}>Pay as guest</button>
            }
            <button className="tertiary-button"
              onClick={() => this.setState({showPopup: !this.state.showPopup})}>
              <img className="request-page-button-logo" src={logoImg} alt=""></img>
              How does this work?
            </button>
          </div>);
      case steps.ENTER_NAME:
        return (<EnterName
          amountInPence={this.props.requestInfo.amountInPence}
          requesterName={this.props.ownerInfo.displayName}
          goBack={() => this.setState({step: steps.INITIAL})}
          handleSubmit={this.handleEnterNameSubmit}/>);
      case steps.SELECT_BANK:
        return (<SelectBank goBack={() => this.setState({step: steps.INITIAL})} onClick={this.handleSelectBankSubmit}/>);
      case steps.CONFIRM:
        return (<ConfirmBank
          goBack={() => this.setState({step: steps.SELECT_BANK, isPaymentLinkLoading: true})} 
          ownerInfo={this.props.ownerInfo}
          provider = {this.state.remitterProvider}
          isLoading = {this.state.isPaymentLinkLoading}
          paymentLink = {this.state.paymentLink}
          amountInPence={this.props.requestInfo.amountInPence}
          message={this.props.requestInfo.message}/>);
      default:
        console.error("Unexpected step", this.state.step);
    }
  }
}

export default withAuthentication(withFirebase(withRouter(RequestPage)));