import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import queryString from 'query-string'

import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";

import CreateAccount from './CreateAccount';
import ConnectBank from './ConnectBank';
import SelectBank from './SelectBank';
import CreatingAccount from './CreatingAccount';
import AccountCreated from './AccountCreated';
import ChooseUrl from './ChooseUrl';

const steps = {
  CREATE_ACCOUNT: 0,
  CHOOSE_URL: 1,
  CONNECT_YOUR_BANK: 2,
  SELECT_YOUR_BANK: 3,
  CREATING_ACCOUNT: 4,
  ACCOUNT_CREATED: 5,
}

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleChooseUrlDone = this.handleChooseUrlDone.bind(this);
    this.handleConnectBankDone = this.handleConnectBankDone.bind(this);
    this.handleAccountCreatedDone = this.handleAccountCreatedDone.bind(this);
    this.handleAccountSaved = this.handleAccountSaved.bind(this);
    this.handleBack = this.handleBack.bind(this);

    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.code && queryParams.state) {
      var exchangeCode = queryParams.code;
      var pageId = queryParams.state;
      this.state = {
        currentStep: steps.CREATING_ACCOUNT,
        paymentPageUrl: pageId,
        exchangeCode: exchangeCode,
      };
    } else {
      this.state = {
        currentStep : steps.CREATE_ACCOUNT,
      };
    }
  }

  getUserPageId(uid) {
    return (
      this.props.firebase.db.collection("paymentPages").where("user", "==", uid).get()
      .then(result => {
        if (result.docs.length > 0) {
          return (result.docs[0].id);
        } else {
          return null;
        }
      })
      .catch(error => {
        console.log('Error getting page for user', uid, error);
        return null;
      }));
  }

  handleSignIn(authUser) {
    this.getUserPageId(authUser.uid)
    .then(pageId => {
      if (pageId !== null) {
        // User has already created a page before, send them there.
        this.props.history.push(pageId);
      } else {
        this.setState({currentStep: steps.CHOOSE_URL});
      }
    }); 
  }

  handleChooseUrlDone(paymentPageUrl) {
    this.setState({
      currentStep: steps.CONNECT_YOUR_BANK,
      paymentPageUrl: paymentPageUrl
    });
  }

  handleConnectBankDone() {
    this.setState({currentStep: steps.SELECT_YOUR_BANK});
  }

  handleAccountCreatedDone() {
    this.props.history.push(this.state.paymentPageUrl);
  }

  handleAccountSaved() {
    this.setState({currentStep: steps.ACCOUNT_CREATED});
  }

  handleBack() {
    let currentStep = this.state.currentStep;
    // Also go back to landing if they've just signed in, so they can sign out if they want
    if (currentStep === 0 || currentStep === 1) {
      this.props.history.push(ROUTES.LANDING);
    } else {
      this.setState({currentStep: currentStep - 1});
    }
  }

  // TODO: check we have some contact method (email or phone) for the users, and error/prompt for one if not.

  render() {
    switch (this.state.currentStep) {
      case steps.CREATE_ACCOUNT:
        return (<CreateAccount handleSignIn={this.handleSignIn} handleBack={this.handleBack}/>);
      case steps.CHOOSE_URL:
        return (<ChooseUrl handleConfirm={(paymentPageUrl) => this.handleChooseUrlDone(paymentPageUrl)} handleBack={this.handleBack}/>);
      case steps.CONNECT_YOUR_BANK:
        return (<ConnectBank handleConfirm={this.handleConnectBankDone} handleBack={this.handleBack}/>);
      case steps.SELECT_YOUR_BANK:
        return (<SelectBank state={this.state.paymentPageUrl} handleBack={this.handleBack}/>);
      case steps.CREATING_ACCOUNT:
        return (<CreatingAccount pageId={this.state.paymentPageUrl} exchangeCode={this.state.exchangeCode} handleAccountSaved={this.handleAccountSaved} handleBack={this.handleBack}/>);
      case steps.ACCOUNT_CREATED:
        return (<AccountCreated pageId={this.state.paymentPageUrl} handleContinue={this.handleAccountCreatedDone} handleBack={this.handleBack}/>);
      default:
        console.error("Unexpected account creation step", this.state.currentStep);
    }
  }
}

const SignUpPage = withRouter(withFirebase(SignUp));

export default SignUpPage;