import React from 'react';
import { withAuthorization } from '../Session';
import FadeIn from "react-fade-in";

const Account = ({ authUser }) => (
  <div className="page-container">
    <div className="header-container">
      <FadeIn>
        <h1 className="header-text">Profile</h1>
      </FadeIn>
    </div>
    <div className="page-content-container">
      <FadeIn>
        <div className="page-card">
          <div className="card-title">Name</div>
          <div className="card-content">
            {authUser.displayName}
          </div>
        </div>
      </FadeIn>
      <FadeIn>
        <div className="page-card">
          <div className="card-title">Email</div>
          <div className="card-content">{authUser.email}</div>
        </div>
      </FadeIn>
      {/* <div className='page-card'>
        <div className='card-title'>Avatar</div>
        <div className='card-content'>
          <img url={this.props.authUser.photoURL}>
          </img>
        </div>
      </div> */}
    </div>
  </div>
);
const AccountPage = Account;
// Require users be signed in to see this page
const condition = authUser => !!authUser;
export default withAuthorization(condition)(AccountPage);