import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { withFirebase } from '../Firebase';
import { withAuthentication } from "../Session";

import BurgerMenu from '../BurgerMenu';
import ProfileInfo from './ProfileInfo';
import EnterAmount from './EnterAmount';
import SelectBank from './SelectBank';
import PaymentHistory from './PaymentHistory';
import SendAction from './SendAction';
import TrustPopUp from '../TrustPopUp';
import ConfirmBank from './ConfirmBank';

import logoImg from "../../assets/logo.svg";

const paymentSteps = {
  INITIAL: 0,
  ENTER_AMOUNT: 1,
  SELECT_BANK: 2,
  CONFIRM: 3,
}

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.handleEnterAmountSubmit = this.handleEnterAmountSubmit.bind(this);
    this.handleSelectBankSubmit = this.handleSelectBankSubmit.bind(this);
    this.sharePageLink = this.sharePageLink.bind(this);

    this.state = {
      paymentStep: paymentSteps.INITIAL,
      showPopup: false,
      isPaymentLinkLoading: true,
    };
  }

  convertAmountInPoundsStringToAmountInPenceInt(amountInPoundsString) {
    var decimalParts = (amountInPoundsString + "00").split(".");
    if (decimalParts.length === 1) {
      // There's no decimal point, we've already add two zeros to multiply by 100, so we're done.
      return Number.parseInt(decimalParts[0]);
    }
    if (decimalParts.length === 2) {
      if (decimalParts[1].length > 4) {
        throw Error("Not a whole number of pence: ", amountInPoundsString)
      }
      var amountInPenceString = decimalParts[0] + decimalParts[1].slice(0,2) + "." + decimalParts[1].slice(2);
      return Number.parseInt(amountInPenceString);
    }
    throw Error("Could not parse amount: ", amountInPoundsString);
  }

  handleEnterAmountSubmit(event) {
    event.preventDefault();
    this.setState({
      paymentAmountInPence: this.convertAmountInPoundsStringToAmountInPenceInt(event.target.amountInPounds.value.toString()),
      paymentMessage: event.target.message ? event.target.message.value : null,
      paymentFrom: event.target.from ? event.target.from.value : null,
      paymentStep: paymentSteps.SELECT_BANK,
    });
  }

  handleSelectBankSubmit(provider) {
    this.setState({remitterProvider: provider})
    this.props.firebase.createPaymentLink({
      pageId: this.props.pageId,
      amountInPence: this.state.paymentAmountInPence,
      reference: "Paid with Nimble",
      message: this.state.paymentMessage,
      providerId: provider.id,
      remitterName: this.props.authUser ? this.props.authUser.displayName : this.state.paymentFrom ? this.state.paymentFrom : null,
    }).then(result => {
        this.setState({paymentLink: result.data.paymentLink, isPaymentLinkLoading: false})
    })
    //go to loading/click to confirm page
    this.setState({paymentStep: paymentSteps.CONFIRM})
  }

  // TODO: Identical code in ProfilePage - confusingly this code is called for share profile button on profile page
  sharePageLink() {
    var data = {
      title: "Pay " +  this.props.ownerInfo.displayName + " with Nimble",
      text: 'Pay quickly and securely with Nimble, no sign-up needed.',
      url: window.location.protocol + '//' + window.location.host + '/' + this.props.pageId,
    }
    this.share(data).catch((error) => {
      console.error('Error sharing page link: ', error);
    });
  }

  // TODO: Identical code in ProfilePage
  share(data) {
    if (navigator.share) {
      return navigator.share(data)
        // TODO: Record on the request whether it was successfully shared?
        .then(() => console.log('Successful share'))
    } else {
      // TODO: handle browsers other than Chrome/Samsung on Android and Safari on iOS
      return Promise.reject(new Error("Sharing not supported. Attempted to share: " + JSON.stringify(data)));
    }
  }

  render() {
    switch (this.state.paymentStep) {
      case paymentSteps.INITIAL:
        return (
          <div className="page-container">
            {this.state.showPopup && <TrustPopUp close={() => this.setState({showPopup: false})}/>}

            <div className="page-content-container">
              <BurgerMenu/>
              <div className="profile-card">
                <ProfileInfo ownerInfo={this.props.ownerInfo} signedInUserOwnsThisPage={false} shareLink={this.sharePageLink}/>
                <div className="profile-card-main-button-spacer"></div>
              </div>
              <div className="payment-page-body">
                <div className="profile-payment-buttons">
                    <button className="primary-button-paired" onClick={() => this.setState({paymentStep: paymentSteps.ENTER_AMOUNT})}>Send money</button>
                    <button className="disabled-button-paired">Request money</button>
                </div>
                {this.props.authUser !== null ?
                <PaymentHistory owner={this.props.ownerInfo.ownerId} paymentHistory={this.props.paymentHistory} pageId={this.props.pageId}/> :
                <div>
                  <div className="payment-page-dotted-line-send"></div>
                  <div className="payment-page-dotted-line-request"></div>
                  <div className="payment-page-send-text">
                    Transfer money directly from your bank’s app, for free, no sign up required 
                  </div>
                  <div className="payment-page-request-text">
                    To request money, you need a <a href="/">Nimble account</a>
                    <br/>
                    (also free)
                  </div>                  
                </div>}
              </div>
            </div>
            {!this.props.authUser &&
            <button className="tertiary-button payment-page-how-does-this-work-button"
              onClick={() => this.setState({showPopup: !this.state.showPopup})}>
              <img className="request-page-button-logo" src={logoImg} alt=""></img>
              How does this work?
            </button>}
          </div>);
      case paymentSteps.ENTER_AMOUNT:
        return (<EnterAmount
          goBack={() => this.setState({paymentStep: paymentSteps.INITIAL})}
          sendAction={SendAction.PAYMENT}
          ownerInfo={this.props.ownerInfo}
          handleSubmit={this.handleEnterAmountSubmit}/>);
      case paymentSteps.SELECT_BANK:
        return (<SelectBank goBack={() => this.setState({paymentStep: paymentSteps.ENTER_AMOUNT})} onClick={this.handleSelectBankSubmit}/>);
      case paymentSteps.CONFIRM:
        return (<ConfirmBank
          goBack={() => this.setState({paymentStep: paymentSteps.SELECT_BANK, isPaymentLinkLoading: true})} 
          ownerInfo={this.props.ownerInfo}
          provider = {this.state.remitterProvider}
          isLoading = {this.state.isPaymentLinkLoading}
          paymentLink = {this.state.paymentLink}
          amountInPence={this.state.paymentAmountInPence}
          message={this.state.paymentMessage}/>);
      default:
        console.error("Unexpected payment step", this.state.paymentStep);
    }
  }
}

export default withAuthentication(withFirebase(withRouter(PaymentPage)));