import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";

import { withAuthorization } from '../Session';

import pendingImg from "../../assets/undraw_empty_xct9 1.svg"
import linkIcon from "../../assets/link-icon.svg"
import resendIcon from "../../assets/resend-icon.svg"
import markDoneIcon from "../../assets/tick-icon.svg"

class Requests extends Component {
  render() {
    if (this.props.paymentRequests === null) {
      return <div className="spinner"><Spinner animation="border" /></div>;
    }

    if (this.props.paymentRequests.length === 0) {
      return (
        <div className="profile-page-tab-placeholder">
          <div>
            <img src={pendingImg} alt=""></img>
            <br/>
            <br/>
            <div>No pending requests</div>
          </div>
        </div>
      );
    }

    return (
      <div className="payment-request-list">
        {this.props.paymentRequests
          .sort((a,b) => b.created - a.created) // Reverse sort by date
          .filter(request => !request.markedDone)
          .map(item =>
        <div key={item.requestId} className="payment-request-item">
          <div className="payment-request-item-body">
            <img className="payment-request-photo" alt="" src={linkIcon}></img>
            <div className="payment-request-users-and-reference">
              <div className="payment-request-users">
                You sent a payment link
              </div>
              <div className="payment-request-reference">
                {item.message ? item.message : ""}
              </div>
              <div className="payment-request-paid-by">
                <div className="payment-request-paid-count">
                  {item.paidBy ?
                    item.paidBy.length === 1 ? "1 person paid" :
                    item.paidBy.length.toString() + " people paid" :
                  "0 people paid"}
                </div>
                <div className="payment-request-paid-names">
                  {item.paidBy ? item.paidBy.join(", ") : ""}
                </div>
              </div>
            </div>
            <div className="payment-request-amount-and-date">
              <div className="payment-request-amount">
                {"£" + (Number.parseInt(item.amountInPence) / 100).toFixed(2)}
              </div>
              <div className="payment-request-date">
                {moment(item.created).fromNow()}
              </div>
              {item.paidBy && 
              <div className="payment-request-paid-by payment-request-amount-positive">
              {"+£" + (Number.parseInt(item.amountInPence * item.paidBy.length) / 100).toFixed(2)}
              </div>}
            </div>
          </div>
          <div className="payment-request-item-buttons">
            <button className="payment-request-item-button" onClick={() => this.props.handleRequestResend(item.requestId, item.amountInPence, item.message)}>
              <img className="payment-request-item-button-icon " src={resendIcon} alt=""></img>
              Resend
            </button>
            <button className="payment-request-item-button" onClick={() => this.props.markRequestDone(item.requestId)}>
              <img className="payment-request-item-button-icon " src={markDoneIcon} alt=""></img>
              Mark as done
            </button>
          </div>
        </div>)
      }
    </div>);
  }
}

// Only signed-in users see history
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(Requests));